export type MenuSection = {
	id: number;
	order: number;
	ungroupedSection?: boolean;
	translations: {
		language: string;
		text: string;
	}[];
	items: MenuItem[];
	created?: boolean;
	modified?: boolean;
	deleted?: boolean;
};

export type MenuItem = {
	id: number;
	order: number;
	section: {
		language: string;
		text: string;
	}[];
	permission: string;
	endIcon: string;
	active: boolean;
	startIcon: string;
	startIconSVG: IIcon;
	created?: boolean;
	modified?: boolean;
	deleted?: boolean;

	translations: MenuItemTranslation[];
};

export type MenuItemTranslation = {
	language: string;
	text: string;
	externalLink?: {
		active: boolean;
		inAppBrowser: boolean;
		url: string;
	};
	externalAppLink?: {
		active: boolean;
		deepLinkAndroid: string;
		deepLinkIOS: string;
		downloadAppAndroid: string;
		downloadAppIOS: string;
		webUrl: string;
	};

	file?: {
		active: boolean;
		fileName: string;
		url: string;
		file?: any;
	};

	internalLink?: {
		active: boolean;
		url: string;
	};

	customContent?: {};
};

export type MenuConfig = MenuSection[];

export enum ConfigType {
	EXTERNAL_LINK = 'externalLink',
	FILE = 'file',
	EXTERNAL_APP_LINK = 'externalAppLink',
	INTERNAL_LINK = 'internalLink',
}

export type IIcon = {
	id: number;
	name: string;
	url: string;
	svgContent?: string;
	svgName?: string;
	delete?: boolean;
	inUse?: boolean;
};

export type ServiceConfig = {
	id: number;
	images: {
		backgroundDesktop: string;
		backgroundMobile: string;
	};
	subtitleTranslations: {
		language: string;
		translation: string;
	}[];
	titleTranslations: {
		language: string;
		translation: string;
	}[];
	details: ServiceDetail[];
};

export type ServiceDetail = {
	id: number;
	enabled: boolean;
	action: 'create' | 'update' | 'delete';
	translationsName: { language: string; translation: string }[];
	translationsContent: { language: string; translation: string }[];
};
